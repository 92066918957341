import { mapActions, mapState } from 'vuex';
import { myMixin } from '../../../mixins';

export default {
    name: "UserIndex",
    mixins: [myMixin],
    data: () => ({
        collections: [],
        openAll: false,
        renderComponent: true,
        params: {},
        brands: [],
        message: '',
        error: false,
        showLogImport: false,
        loadingCampuses: false,
        selectedIndexCampuses: [],
        errors: {},
        selectAllCampuses: false,
        loadindSelectCampuses: false,
        loading: false,
        meta: {},
        tab: 0,
        itemDelete: {},
        showImage: false,
        selectedUser: null,
        model: {
            campuses: []
        },
        institutions: [],
        show: false,
        showForm: false,
        titleForm: 'Cadastrar usuário',
        text: '',
        deleting: false,
        updating: false,
        headers: [
            { text: 'Nome', value: 'name'},
            { text: 'Email', value: 'email'},
            { text: 'Status', value: 'active', type: 'boolean'},
            { text: 'Ações', value: 'actions', sortable: false}
        ],
        campuses: [],
        campusesChecked: []
    }),
    computed: {
        ...mapState('auth', ['acesso', 'user']),
        
        institutionsSelected: function() {

            if(this.model.institutions != undefined) {

                var model = this.model.institutions;

                return this.institutions.filter(function(i){
                    for (var f=0; f< model.length; f++){
                        if(model[f].id === i.id){
                            return true
                        } else {
                            false
                        }
                    }
                })

            }
        },

        disableFields (){
            
            if(this.user.is_internal){
                return false;
            }else{
                return this.model.is_admin;
            }
            
           
        }
    },
    methods: {
        ...mapActions('user', ['ActionIndexUsers', 'ActionDeleteUser', 'ActionUpdateUser', 'ActionCreateUser']),
        ...mapActions('institution', ['ActionIndexInstitutions']),
        ...mapActions('campus', ['ActionFindCampuses']),
        
        getData() {
            
            this.error = false;
            this.message = '';
            this.loading = true;
            const params = this.params;

            let withParam = this.isAnima() ? 'roles,user,departaments' : 'roles,courses,user,departaments';
            
            Object.assign(params, {
                with: withParam,
            });
            
            this.ActionIndexUsers(params)
                .then((res) => {
                    this.collections = res.data;
                    this.meta = res.meta;
                })
                .finally(() => {
                    this.loading = false
                })
        },

        getUserCampuses() {
            
            this.selectedIndexCampuses = [];
            
            if(!this.model || !this.model.id){
                this.loadingCampuses = false;
                return;
            }
            
            let payload = {
                find_columns: {
                    id: this.model.id,
                },
                with: 'campuses',
            };
            
            this.ActionIndexUsers(payload)
                .then((res) => {
                    
                    if(res.data && res.data.length > 0){
                        
                        let campuses = res.data[0].campuses;

                        for (let index = 0; index < this.campuses.length; index++) {
                           
                            if(campuses.find(c => c.id == this.campuses[index].id)){
                                if(!this.selectedIndexCampuses.includes(index)){
                                    this.selectedIndexCampuses.push(index);
                                }
                            }
                            
                        }
                        
                    }
                    
                })
                .finally(() => {
                    this.loadingCampuses = false;
                })
        },

        save()
        {
            this.updating = true
            this.message = ''
            
            let payload = {};
            Object.assign(payload, this.model);

            payload.institution_id = this.acesso.instituicao.id;
            payload.campuses = [];
            payload.courses = (this.model.courses && this.model.courses.length > 0) ? this.model.courses.map(i => ({id: i['id'] })) : [];

            this.selectedIndexCampuses.forEach(index => {
                
                let campus = this.campuses[index];
                
                payload.campuses.push({
                    id:             campus.id, 
                    institution_id: campus.institution_id, 
                    brand_id:       campus.brand_id
                });
            });

            if(this.model.id) {
                this.ActionUpdateUser(payload)
                .then((res) => {
                    this.message = res.message;
                    this.error = false;
                    this.show = false;
                    this.showForm = false
                    this.getData();
                })
                .catch((error) =>{
                    this.message = error.message
                    this.error = true
                    this.errors = error.errors;

                    if(this.errors.campuses){
                        this.tab = 1;
                    }

                })
                .finally(() => {
                    this.updating = false
                })
            } else {

                payload.access_profile_id = 3;

                this.ActionCreateUser(payload)
                .then((res) => {
                    this.message = res.message
                    this.showForm = false
                    this.model = {}
                    this.getData()
                })
                .catch((error) =>{
                    this.message = error.message
                    this.error = true
                    this.errors = error.errors
                })
                .finally(() => {
                    this.updating = false
                })

            }
        },

        async getInstitutions(){
            var params = {
                with: 'campuses',
                column_order: 'name',
            }
            await this.ActionIndexInstitutions(params)
                .then((res) => {
                    this.institutions = res.data;
                })
        },

        forceRerender () {
            
            this.renderComponent = false;
    
            this.$nextTick(() => {
                this.renderComponent = true;
            });
    
        },


        async getCampuses() {
            
            this.loadingCampuses = true;
            this.campuses = [];
            
            var params = {
                column_order: 'brand_id;name',
                with: 'brand,institution',
            }
            await this.ActionFindCampuses(params)
                    .then((res) => {
                        
                        let ids = [];

                        res.data.forEach(c => {

                            if(c.campus_id_parent && !ids.includes(c.campus_id_parent)){
                                ids.push(c.campus_id_parent);
                            }else if(!c.campus_id_parent){
                                ids.push(c.id);
                            }

                        });

                        // Incluir na lista apenas os campus gerenciais
                        res.data.forEach(c => {

                            if(ids.includes(c.id)){
                                this.campuses.push(this.getItemCampus(c));
                            }

                        });

                    }).finally(() => {
                        this.getUserCampuses();
                    })
        },

        selectCampuses(event)
        {
            
            this.loadindSelectCampuses = true;
            
            this.$nextTick(() => {
                
                let originals = this.selectedIndexCampuses;
                let selecteds = [];
                let brands = this.brands.map(b => b.id);

                if(event){
                    
                    if(brands.length > 0){
                        
                        originals.forEach(index => {
                            
                            let campus = this.campuses[index];
                            
                            if(campus && !brands.includes(campus.brand_id)){
                                selecteds.push(index);
                            }
                        });

                        for (let index = 0; index < this.campuses.length; index++) {
                            
                            let campus = this.campuses[index];

                            if(brands.includes(campus.brand_id)){
                                selecteds.push(index);
                            }
                            
                        }
                        
                    }else{
                        for (let index = 0; index < this.campuses.length; index++){
                            selecteds.push(index);
                        }
                    }
                    
                }else{
                    
                    if(brands.length > 0){
                        
                        originals.forEach(index => {
                            
                            let campus = this.campuses[index];
                            
                            if(campus && !brands.includes(campus.brand_id)){
                                selecteds.push(index);
                            }
                        });

                    }
                }

                this.selectedIndexCampuses = selecteds;
                this.loadindSelectCampuses = false;

            });

            
        },

        getItemCampus(campus){
            
            return {
                id:                 campus.id, 
                name:               campus.name,
                code:               campus.code,
                institution_id:     campus.institution_id, 
                brand_id:           campus.brand_id,
                campus_id_parent:   campus.campus_id_parent,
                brand:              { id: campus.brand_id, name: campus.brand.name },
                institution:        { id: campus.institution_id, name: campus.institution.name },
            };
        },

        deleteItem()
        {
            this.deleting = true
            this.message = ''

            this.ActionDeleteUser({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                    this.message = error.message;
                    this.error = true;
                })
                .finally(() => {
                    this.deleting = false
                })
        },

        showAvatar(item){
            
            if(item && item.url_avatar){
                this.selectedUser = item;
                this.showImage = true;
            }
        }
    },
    watch: {
        
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        }, //Função para tratar quando um item for exibido para deletar
        itemDelete(item_new, item_old){
            if(item_new !== item_old && Object.keys(item_new).length > 0)
            {
                this.show = true
                this.text = "Deseja excluir " + item_new.name + "?"
            } else {
                this.show = false
                this.text = ''
            }
        }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
        
        show(show_new, show_old) {
            
            if(show_new != show_old && !show_new) {
                this.itemDelete = {};
            }

            
        },

        model(item_new, item_old){
            
            if(item_new !== item_old && (this.model && this.model.id))
            {
                this.titleForm = 'Editar usuário'
                this.showForm = true
                this.errors = {}
                this.campusesChecked = (this.model && this.model.campuses) ? this.model.campuses : [];

            } else if(Object.keys(this.model).length === 0){
                
                this.titleForm = 'Cadastrar usuário';
                this.showForm = false;
                this.errors = {};
            }           
        },
        campusesChecked(campuses){
            
            if((campuses && this.model.campuses) && campuses.length !== this.model.campuses.length) {
                this.model.campuses = campuses;
            }

        },

        showForm(show_new, show_old) {
            
            if(show_new){
                
                this.selectAllCampuses = false;
                
                if(!this.model || !this.model.id){
                    this.model = { 
                        campuses: [],
                        active: 1
                     };
                   
                }else{
                    if(this.campuses && this.model.campuses){
                        this.selectAllCampuses = this.campuses.length == this.model.campuses.length;
                    }
                }

                this.openAll = false;

                if(show_new){
                    this.tab = 0;
                    this.brands = [];
                    this.institutions = [];
                    this.selectedIndexCampuses = [];
                }

            }else{
                this.model = {};
            }
        },

        tab(val){

            if(val == 1 && this.institutions.length === 0){
                this.getInstitutions();
                this.getCampuses();
            }

        },
    },
}